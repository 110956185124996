import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Outersuccessbreadcrumb from "../components/resources/International-day/international-day-breadcumb";

import Outersucesscontentpage from "../components/resources/International-day/international-day-content";

// markup
const InnerawarnessPage = () => {
  return (
    <div>
      <Seo title="Humans Of Heartfulness" />

      <Layout isSticky>
        <section>
          <Outersuccessbreadcrumb />
        </section>
        <section>
          <Outersucesscontentpage />
        </section>
      </Layout>
    </div>
  );
};

export default InnerawarnessPage;
